import { styled } from '@linaria/react'
import { animated, useTransition } from '@react-spring/web'
import { WaveOnboardingBoxes } from 'components/designSystem/Wave/WaveBoxes'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC, useContext } from 'react'
import { OnboardingContext } from './Onboarding.context'
import { WaveController } from './WaveController'
import { useIsWaveVisibleOnCurrentWindowHeight } from './hooks/useIsWaveVisibleOnCurrentWindowHeight'

export const OnboardingWave: FC<{
  step: number
  hidden?: boolean
}> = ({ step, hidden }) => {
  const { mobile } = useShallowEqualSelector(({ systemView: { mobile } }) => ({
    mobile,
  }))

  const { loginFlow } = useContext(OnboardingContext)
  const waveVisibleOnCurrentWindowHeight = useIsWaveVisibleOnCurrentWindowHeight()

  const visible = waveVisibleOnCurrentWindowHeight && !hidden && mobile !== null

  const transitions = useTransition(visible, {
    from: { transform: 'translateY(300px)', opacity: 0 },
    enter: { transform: 'translateY(0px)', opacity: 1, delay: 500 },
    leave: { transform: 'translateY(300px)', opacity: 0 },
    initial: null,
    config: { duration: 500 },
  })

  if (mobile === null) {
    return null
  }

  return transitions(
    (springs, show) =>
      show && (
        <WaveWrapper style={springs}>
          <WaveInner
            style={{
              transform: mobile ? `translateY(${step === 0 ? 6 : 50}px)` : '',
            }}
          >
            <WaveController
              step={step}
              mobile={mobile}
              boxesElements={<WaveOnboardingBoxes loginFlow={loginFlow} />}
            />
          </WaveInner>
        </WaveWrapper>
      )
  )
}

const WaveWrapper = styled(animated.div)`
  height: 100%;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
`
const WaveInner = styled.div`
  height: 100%;
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  transition: transform 2s;
`
