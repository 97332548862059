import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { RestorePasswordSuccess } from './RestorePasswordSuccess'
import { Replace } from 'components/system/Replace'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { restorePasswordPath } from './paths'
import parsePhoneNumberFromString from 'libphonenumber-js'

export const RestorePasswordPhoneSuccessStep: FC = () => {
  const { phone, locale } = useShallowEqualSelector(
    ({
      authorizationReducer: {
        form: { phone },
      },
      systemReducer: { locale },
    }) => ({
      phone,
      locale,
    })
  )

  const parsedPhoneNumber =
    phone.phoneNumberFull && parsePhoneNumberFromString(phone.phoneNumberFull)

  if (!parsedPhoneNumber) {
    return (
      <Replace
        to={mergeAllUrls(locale, restorePasswordPath)}
        uid="RestorePasswordEnterSmsCode-no-phone-number"
      />
    )
  }

  return (
    <RestorePasswordSuccess
      title={
        <FormattedMessage
          id="app.password_sent"
          defaultMessage="Пароль отправили"
        />
      }
      description={
        <FormattedMessage
          id="app.check_phone_number"
          defaultMessage="Проверь номер {phone}.{br}Постарайся не забыть."
          values={{
            phone: parsedPhoneNumber.formatInternational(),
            br: <br />,
          }}
        />
      }
    />
  )
}
