import { SyntheticEvent, useCallback } from 'react'
import { v4 } from 'uuid'

import { fullStopPropagation } from 'functions/fullStopPropagation'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { mergeFullUrls } from 'functions/mergeAllUrls'
import { vkRedirectPagePath } from 'components/system/third-party/vk/vk.paths'
import {
  sharedHost,
  VkRedirectPlace,
} from 'components/system/third-party/vk/vk.constants'
import { VendorSocialList } from 'common-constants/vendorSocialList'
import { findVkAppId } from 'components/system/third-party/vk/vk.functions'

export type VendorClickHandler = (
  event: SyntheticEvent,
  vendorName: string
) => Promise<void>

/**
 * Авторизация без SDK
 * https://id.vk.com/about/business/go/docs/ru/vkid/latest/vk-id/connection/web/auth-without-sdk
 * @param appId
 * @param redirectUri
 * @param redirect_state
 */
const createVkQuery = (appId: number, redirectUri: string) => {
  return `uuid=${v4()}&app_id=${appId}&response_type=silent_token&redirect_uri=${redirectUri}`
}

export const useVkConnectHandler = (
  place: VkRedirectPlace = VkRedirectPlace.authorization,
  relativeRedirectUrl?: string
): [VendorClickHandler] => {
  const { locale, partnerId } = useShallowEqualSelector(
    ({ systemReducer: { locale, partnerId } }) => ({
      locale,
      partnerId,
    })
  )

  const handleVendorClick = useCallback(
    async (event: SyntheticEvent, vendorName: string) => {
      if (vendorName === VendorSocialList.vkConnect) {
        // Сразу не даем перейти по ссылки, т.к. никакой ссылки не может быть
        // редиректы только через библиотеку VK ID
        event.preventDefault()
        fullStopPropagation(event)

        const redirectUrl = mergeFullUrls(
          'https',
          sharedHost,
          locale,
          vkRedirectPagePath,
          window.location.host,
          partnerId,
          place,
          relativeRedirectUrl
        )

        window.location.assign(
          `https://id.vk.com/auth?${createVkQuery(
            findVkAppId(partnerId),
            redirectUrl
          )}`
        )
      }
    },
    [locale, partnerId, place, relativeRedirectUrl]
  )

  return [handleVendorClick]
}
