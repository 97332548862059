import React, { FC, SVGProps } from 'react'

export const UnshapedCircleSvg: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.61856 22.3285C5.79473 33.7081 17.5802 34.0183 25.1095 28.5517C36.13 20.5504 33.8099 1.288 19.0193 0.102622C4.22867 -1.08276 -3.60166 8.10392 1.61856 22.3285Z"
        fill="#FFDED0"
      />
    </svg>
  )
}
