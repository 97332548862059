import { postApi } from 'api/fetchApi'
import { operations } from 'api/generated/onboarding'

type Operation = operations['registration_check_username_checkUsername']
type Response = Operation['responses']['204']['schema']
type Body = Operation['parameters']['body']['body']

export const checkUsernameApi = (username: string) => {
  return postApi<Response, Body>('/registration/checkUsername', {
    username,
  })
}
