import React, { SVGProps } from 'react'

export const HeartSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.74371 20.1984C10.4985 20.7242 11.2756 20.948 12 21C12.7244 20.948 13.5015 20.7242 14.2563 20.1985C14.2563 20.1985 21.9174 16.645 22.4519 10.6197C22.985 4.59443 18.9124 3.40993 18.9124 3.40993C13.9924 1.75336 12.4051 5.56223 12 6.94762C11.5949 5.56223 10.0076 1.7533 5.08757 3.40988C5.08757 3.40988 1.01502 4.59438 1.54812 10.6197C2.08261 16.645 9.74371 20.1984 9.74371 20.1984Z"
        fill="currentColor"
      />
    </svg>
  )
}
