import { VoidHandler } from 'common/types'
import { ListItem } from 'components/designSystem/ListItem/ListItem'
import { RoundButton } from 'components/designSystem/RoundButton/RoundButton'
import { PlusSvg } from 'components/designSystem/svgr/PlusSvg'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { LoginVariantText } from './LoginVariantText'
import { ListItemInner } from './styled'
import { UnstyledButton } from 'components/designSystem/Button/UnstyledButton'

export const CreateProfileButton: FC<{ onClick: VoidHandler }> = ({
  onClick,
}) => {
  return (
    <UnstyledButton onClick={onClick} data-name="create-profile-action">
      <ListItem size="M">
        <ListItemInner>
          <RoundButton size="S" interactive={false}>
            <PlusSvg />
          </RoundButton>

          <LoginVariantText>
            <FormattedMessage
              id="app.create.profile"
              defaultMessage="Создать профиль"
            />
          </LoginVariantText>
        </ListItemInner>
      </ListItem>
    </UnstyledButton>
  )
}
