import { styled } from '@linaria/react'
import { fetchVendorsListIfNeededAction } from 'actions/authorization/vendorsListAction'
import { goBackToAction } from 'actions/route/routeAction'
import { VendorSocialList } from 'common-constants/vendorSocialList'
import { ChangeLanguageBottomSheet } from 'components/block/ChangeLanguageBottomSheet/ChangeLanguageBottomSheet'
import { CitiesBottomSheet } from 'components/block/CitiesBottomSheet/CitiesBottomSheet'
import { CountriesBottomSheet } from 'components/block/CountriesBottomSheet/CountriesBottomSheet'
import { OpenVendorAppBottomSheet } from 'components/block/OpenVendorAppBottomSheet/OpenVendorAppBottomSheet'
import { useBottomSheet } from 'components/designSystem/BottomSheet/BottomSheet'
import { breakpoints } from 'components/designSystem/shared/breakpoints'
import { UniNoticeId } from 'components/uninotice/uninotice.types'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { push } from 'functions/router'
import { sleep } from 'functions/sleep'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC, useEffect } from 'react'
import { NOTIFICATION_REQUIRED_VENDORS } from '../Boarding/SocialNetworks/useVendorAppInstalledNotification'
import { AgreementBottomSheet } from './AgreementBottomSheet'
import { ConfidentialityBottomSheet } from './ConfidentialityBottomSheet'
import { CreateProfileOrLoginBlock } from './CreateProfileOrLoginBlock'
import { HeaderMobile } from './HeaderMobile'
import { LastAuthMethodBlock } from './LastAuthMethodBlock'
import { LoginVariantsBottomSheet } from './LoginVariantsBottomSheet'
import { WelcomePageFooter } from './WelcomePageFooter'
import { WelcomePageTitleBlock } from './WelcomePageTitleBlock'
import { loginPath, selectGenderPath } from './paths'
import { StepCommonProps } from './types'
import { loginVariantsPath } from '../Boarding/boarding.paths'

export const WelcomeStep: FC<StepCommonProps> = ({ active }) => {
  const {
    lastAuthMethod,
    locale,
  } = useShallowEqualSelector(
    ({
      authorizationReducer: { lastAuthMethod },
      systemReducer: { locale },
    }) => ({ lastAuthMethod, locale })
  )
  const dispatch = useAppDispatch()

  useEffect(() => {
    // Потому что resetVendorsListAction в resetVendorsListAction при разлогине может отработать раньше
    window.setTimeout(() => {
      dispatch(fetchVendorsListIfNeededAction())
    })
  }, [dispatch])

  const onCreateProfileClick = async () => {
    dispatch(goBackToAction(mergeAllUrls(locale)))
    await pauseForSmoothness()
    dispatch(push(mergeAllUrls(locale, selectGenderPath)))
  }
  const handleLoginWithEmail = async () => {
    dispatch(goBackToAction(mergeAllUrls(locale)))
    await pauseForSmoothness()
    dispatch(push(mergeAllUrls(locale, loginPath)))
  }

  const {
    ref: openVendorAppBottomSheetRef,
    open: openVendorAppBottomSheet,
  } = useBottomSheet()

  const handleVendorClick = (vendorName: string) => {
    if (
      NOTIFICATION_REQUIRED_VENDORS.includes(vendorName as VendorSocialList)
    ) {
      openVendorAppBottomSheet(vendorName)
    }
  }

  return (
    <WelcomeInner>
      <HeaderMobile visible={active} />

      <WelcomePageTitleBlock welcomeBack={Boolean(lastAuthMethod)} />

      {lastAuthMethod ? (
        <LastAuthMethodBlock onVendorClick={handleVendorClick} />
      ) : (
        <CreateProfileOrLoginBlock />
      )}

      <WelcomePageFooter />

      <LoginVariantsBottomSheet
        showOnPath={mergeAllUrls(locale, loginVariantsPath)}
        onCloseReplaceToPath={mergeAllUrls(locale)}
        onLoginWithEmailClick={handleLoginWithEmail}
        onVendorClick={handleVendorClick}
        onCreateProfileClick={lastAuthMethod ? onCreateProfileClick : undefined}
      />
      <OpenVendorAppBottomSheet
        noticeId={UniNoticeId.externalMessengerOpenForAuth}
        bottomSheetRef={openVendorAppBottomSheetRef}
      />
      <AgreementBottomSheet />
      <ConfidentialityBottomSheet />
      <CountriesBottomSheet />
      <CitiesBottomSheet />
      <ChangeLanguageBottomSheet />
    </WelcomeInner>
  )
}

const WelcomeInner = styled.div`
  width: 100%;
  height: 100%;
  padding-left: var(--spacing-16px, 16px);
  padding-right: var(--spacing-16px, 16px);

  @media (min-width: ${breakpoints.mobile}px) {
    padding-left: var(--spacing-80px, 80px);
    padding-right: var(--spacing-80px, 80px);
  }

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
`

const pauseForSmoothness = () => sleep(300)
