import React, { SVGProps } from 'react'

export const SlideSvg = ({
  _ref,
  ...restProps
}: SVGProps<SVGSVGElement> & {
  _ref: React.LegacyRef<SVGSVGElement> | undefined
}) => (
  <svg
    ref={_ref}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="xMaxYMid slice"
    width={375}
    height={812}
    viewBox="0 0 375 812"
    style={{
      width: '100%',
      height: '100%',
    }}
    {...restProps}
  >
    <defs>
      <clipPath id="_R_G_L_0_C_0">
        <path
          id="_R_G_L_0_C_0_P_0"
          fillRule="nonzero"
          d="M0 0 C0,0 0,812 0,812 C0,812 375,812 375,812 C375,812 375,0 375,0 C375,0 0,0 0,0z "
        />
      </clipPath>
      <animate
        repeatCount={1}
        dur="1.4333333s"
        begin="indefinite"
        xlinkHref="#_R_G_L_0_G_G_0_L_1_G_D_0_P_0"
        fill="freeze"
        attributeName="d"
        attributeType="XML"
        from="M628.5 -432.5 C628.5,-432.5 628.5,420.5 628.5,420.5 C628.5,420.5 136.5,422 136.5,422 C136.5,422 136.5,-76 136.5,-76 C136.5,-76 136.5,-431 136.5,-431 C136.5,-431 628.5,-432.5 628.5,-432.5z "
        to="M534.5 -431.5 C534.5,-431.5 534.5,421.5 534.5,421.5 C534.5,421.5 -42.5,409.5 -44,407.5 C-45.25,192.75 -48.5,158.74 -48.5,-18.5 C-48.5,-151.79 -42.75,-177.25 -46,-405.5 C-45.5,-406.5 534.5,-431.5 534.5,-431.5z "
        keyTimes="0;0.0348837;0.4767442;0.7209302;0.8255814;1"
        values="M628.5 -432.5 C628.5,-432.5 628.5,420.5 628.5,420.5 C628.5,420.5 136.5,422 136.5,422 C136.5,422 136.5,-76 136.5,-76 C136.5,-76 136.5,-431 136.5,-431 C136.5,-431 628.5,-432.5 628.5,-432.5z ;M628.5 -432.5 C628.5,-432.5 628.5,420.5 628.5,420.5 C628.5,420.5 136.5,422 136.5,422 C136.5,422 136.5,-76 136.5,-76 C136.5,-76 136.5,-431 136.5,-431 C136.5,-431 628.5,-432.5 628.5,-432.5z ;M304.5 -431 C304.5,-431 304.5,422 304.5,422 C304.5,422 139.5,410.5 138,408.5 C131,119 -4.5,158.74 -4.5,-18.5 C-4.5,-151.79 139.75,-178.75 137,-406.5 C137.5,-407.5 304.5,-431 304.5,-431z ;M534.5 -431.5 C534.5,-431.5 534.5,421.5 534.5,421.5 C534.5,421.5 -43.5,409.5 -45,407.5 C-41.25,187.75 -48.5,158.74 -48.5,-18.5 C-48.5,-151.79 -45.75,-177.25 -50,-406.5 C-49.5,-407.5 534.5,-431.5 534.5,-431.5z ;M534.5 -431.5 C534.5,-431.5 534.5,421.5 534.5,421.5 C534.5,421.5 -42.5,409.5 -44,407.5 C-45.25,192.75 2,159.74 2,-17.5 C2,-150.79 -42.75,-177.25 -46,-405.5 C-45.5,-406.5 534.5,-431.5 534.5,-431.5z ;M534.5 -431.5 C534.5,-431.5 534.5,421.5 534.5,421.5 C534.5,421.5 -42.5,409.5 -44,407.5 C-45.25,192.75 -48.5,158.74 -48.5,-18.5 C-48.5,-151.79 -42.75,-177.25 -46,-405.5 C-45.5,-406.5 534.5,-431.5 534.5,-431.5z "
        keySplines="0.87 0 0.667 1;0.87 0 0.667 1;0.333 0 0.667 1;0.333 0 0.833 0.833;0.167 0.167 0.667 1"
        calcMode="spline"
      />
      <animateMotion
        repeatCount={1}
        dur="1.4333333s"
        begin="indefinite"
        xlinkHref="#_R_G_L_0_G_G_0_L_1_G"
        fill="freeze"
        keyTimes="0;0.0930233;0.8255814;0.828443;1"
        path="M479.5 401.5 C479.5,401.5 479.5,401.5 479.5,401.5 C479.5,401.5 265.5,401.5 265.5,401.5 C265.5,401.5 265.5,401.5 265.5,401.5 C265.5,401.5 265.5,401.5 265.5,401.5 "
        keyPoints="0;0;1;1;1"
        keySplines="0.868 0 0.667 1;0.868 0 0.667 1;0.333 0.333 0.667 0.667;0 0 0 0"
        calcMode="spline"
      />
      <animateTransform
        repeatCount={1}
        dur="1.4333333s"
        begin="indefinite"
        xlinkHref="#_R_G_L_0_G_G_0_L_1_G"
        fill="freeze"
        attributeName="transform"
        from="-220.5 4.5"
        to="-220.5 4.5"
        type="translate"
        additive="sum"
        keyTimes="0;1"
        values="-220.5 4.5;-220.5 4.5"
        keySplines="0 0 1 1"
        calcMode="spline"
      />
      <animate
        repeatCount={1}
        dur="1.4333333s"
        begin="indefinite"
        xlinkHref="#_R_G_L_0_G_G_0_L_0_G_D_0_P_0"
        fill="freeze"
        attributeName="d"
        attributeType="XML"
        from="M628.5 -432.5 C628.5,-432.5 628.5,420.5 628.5,420.5 C628.5,420.5 136.5,422 136.5,422 C136.5,422 136.5,-76 136.5,-76 C136.5,-76 136.5,-431 136.5,-431 C136.5,-431 628.5,-432.5 628.5,-432.5z "
        to="M534.5 -431.5 C534.5,-431.5 534.5,421.5 534.5,421.5 C534.5,421.5 -42.5,409.5 -44,407.5 C-45.25,192.75 -48.5,158.74 -48.5,-18.5 C-48.5,-151.79 -42.75,-177.25 -46,-405.5 C-45.5,-406.5 534.5,-431.5 534.5,-431.5z "
        keyTimes="0;0.4767442;0.7209302;0.8255814;1"
        values="M628.5 -432.5 C628.5,-432.5 628.5,420.5 628.5,420.5 C628.5,420.5 136.5,422 136.5,422 C136.5,422 136.5,-76 136.5,-76 C136.5,-76 136.5,-431 136.5,-431 C136.5,-431 628.5,-432.5 628.5,-432.5z ;M304.5 -431 C304.5,-431 304.5,422 304.5,422 C304.5,422 139.5,410.5 138,408.5 C131,119 -4.5,158.74 -4.5,-18.5 C-4.5,-151.79 139.75,-178.75 137,-406.5 C137.5,-407.5 304.5,-431 304.5,-431z ;M534.5 -431.5 C534.5,-431.5 534.5,421.5 534.5,421.5 C534.5,421.5 -47.5,409.5 -49,407.5 C-48.75,191.25 -48.5,158.74 -48.5,-18.5 C-48.5,-151.79 -47,-178.5 -50,-406.5 C-49.5,-407.5 534.5,-431.5 534.5,-431.5z ;M534.5 -431.5 C534.5,-431.5 534.5,421.5 534.5,421.5 C534.5,421.5 -42.5,409.5 -44,407.5 C-45.25,192.75 -6,159.74 -6,-17.5 C-6,-150.79 -42.75,-177.25 -46,-405.5 C-45.5,-406.5 534.5,-431.5 534.5,-431.5z ;M534.5 -431.5 C534.5,-431.5 534.5,421.5 534.5,421.5 C534.5,421.5 -42.5,409.5 -44,407.5 C-45.25,192.75 -48.5,158.74 -48.5,-18.5 C-48.5,-151.79 -42.75,-177.25 -46,-405.5 C-45.5,-406.5 534.5,-431.5 534.5,-431.5z "
        keySplines="0.87 0 0.667 1;0.333 0 0.74 1;0.225 0 0.833 0.833;0.167 0.167 0.667 1"
        calcMode="spline"
      />
      <animateMotion
        repeatCount={1}
        dur="1.4333333s"
        begin="indefinite"
        xlinkHref="#_R_G_L_0_G_G_0_L_0_G"
        fill="freeze"
        keyTimes="0;0.8255814;1"
        path="M459.04 401.5 C459.04,401.5 267.5,401.5 267.5,401.5 C267.5,401.5 267.5,401.5 267.5,401.5 "
        keyPoints="0;1;1"
        keySplines="0.7 0 0.667 1;0 0 0 0"
        calcMode="spline"
      />
      <animateTransform
        repeatCount={1}
        dur="1.4333333s"
        begin="indefinite"
        xlinkHref="#_R_G_L_0_G_G_0_L_0_G"
        fill="freeze"
        attributeName="transform"
        from="-220.5 4.5"
        to="-220.5 4.5"
        type="translate"
        additive="sum"
        keyTimes="0;1"
        values="-220.5 4.5;-220.5 4.5"
        keySplines="0 0 1 1"
        calcMode="spline"
      />
      <animate
        attributeType="XML"
        attributeName="opacity"
        dur="1s"
        from={0}
        to={1}
        xlinkHref="#time_group"
      />
    </defs>
    <g id="_R_G">
      <g id="_R_G_L_0_G">
        <g clipPath="url(#_R_G_L_0_C_0)">
          <g id="_R_G_L_0_G_G_0_L_1_G">
            <path
              id="_R_G_L_0_G_G_0_L_1_G_D_0_P_0"
              fill="#ffded0"
              fillOpacity={1}
              fillRule="nonzero"
              d=" M628.5 -432.5 C628.5,-432.5 628.5,420.5 628.5,420.5 C628.5,420.5 136.5,422 136.5,422 C136.5,422 136.5,-76 136.5,-76 C136.5,-76 136.5,-431 136.5,-431 C136.5,-431 628.5,-432.5 628.5,-432.5z "
            />
          </g>
          <g id="_R_G_L_0_G_G_0_L_0_G" opacity={0.4}>
            <path
              id="_R_G_L_0_G_G_0_L_0_G_D_0_P_0"
              fill="#ffded0"
              fillOpacity={1}
              fillRule="nonzero"
              d=" M628.5 -432.5 C628.5,-432.5 628.5,420.5 628.5,420.5 C628.5,420.5 136.5,422 136.5,422 C136.5,422 136.5,-76 136.5,-76 C136.5,-76 136.5,-431 136.5,-431 C136.5,-431 628.5,-432.5 628.5,-432.5z "
            />
          </g>
        </g>
      </g>
    </g>
    <g id="time_group" />
  </svg>
)
