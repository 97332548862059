import { VendorSocialList } from 'common-constants/vendorSocialList'
import appleSvg from 'components/designSystem/svg/apple.svg'
import facebookSvg from 'components/designSystem/svg/facebook.svg'
import googleSvg from 'components/designSystem/svg/google.svg'
import instagramSvg from 'components/designSystem/svg/instagram.svg'
import mailruSvg from 'components/designSystem/svg/mailru.svg'
import msnSvg from 'components/designSystem/svg/msn.svg'
import naszaklasaSvg from 'components/designSystem/svg/naszaklasa.svg'
import odnoklassnikiSvg from 'components/designSystem/svg/odnoklassniki.svg'
import qqSvg from 'components/designSystem/svg/qq.svg'
import telegramSvg from 'components/designSystem/svg/telegram.svg'
import viberSvg from 'components/designSystem/svg/viber.svg'
import vkontakteSvg from 'components/designSystem/svg/vkontakte.svg'
import wechatSvg from 'components/designSystem/svg/wechat.svg'
import weiboSvg from 'components/designSystem/svg/weibo.svg'
import whatsappSvg from 'components/designSystem/svg/whatsapp.svg'
import yahooSvg from 'components/designSystem/svg/yahoo.svg'
import yandexSvg from 'components/designSystem/svg/yandex.svg'
import React, { FC } from 'react'

export const LoginIconSwitch: FC<{ name: string }> = ({ name }) => {
  const logo = getVendorLogo(name)

  if (!logo) {
    return null
  }

  return <img width={20} height={20} src={logo} alt={name} />
}

const getVendorLogo = (name: string) => {
  switch (name) {
    case VendorSocialList.vkontakte:
    case VendorSocialList.vkConnect:
    case VendorSocialList.vkId:
    case VendorSocialList.vkIdFromServer:
    case VendorSocialList.deprecatedVK:
      return vkontakteSvg
    case VendorSocialList.mailRu:
    case VendorSocialList.mailru:
    case VendorSocialList.mailru_oauth2:
    case VendorSocialList.deprecatedMail:
      return mailruSvg
    case VendorSocialList.google:
    case VendorSocialList.deprecatedGoogle:
      return googleSvg
    case VendorSocialList.yandex:
    case VendorSocialList.deprecatedYandex:
      return yandexSvg
    case VendorSocialList.odnoklassniki:
    case VendorSocialList.deprecatedOdnoklassniki:
      return odnoklassnikiSvg
    case VendorSocialList.apple:
      return appleSvg
    case VendorSocialList.viber:
    case VendorSocialList.deprecatedViber:
      return viberSvg
    case VendorSocialList.telegram:
      return telegramSvg
    case VendorSocialList.yahoo:
    case VendorSocialList.deprecatedYahoo:
      return yahooSvg
    case VendorSocialList.instagramm:
    case VendorSocialList.instagram:
      return instagramSvg
    case VendorSocialList.facebook:
    case VendorSocialList.deprecatedFacebook:
      return facebookSvg
    case VendorSocialList.whatsapp:
      return whatsappSvg
    case VendorSocialList.msn:
    case VendorSocialList.deprecatedMsn:
      return msnSvg
    case VendorSocialList.naszaklasa:
    case VendorSocialList.deprecatedNaszaklasa:
      return naszaklasaSvg
    case VendorSocialList.wechat:
      return wechatSvg
    case VendorSocialList.weibo:
      return weiboSvg
    case VendorSocialList.qq:
      return qqSvg
    case VendorSocialList.hyves:
    case VendorSocialList.myspace:
    case VendorSocialList.twitter:
      return null // Походу не используются
    case VendorSocialList.test:
      return null
    default:
      console.error('Нет иконки для способа входа')
      return null
  }
}
