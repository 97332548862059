import React, { FC, SVGProps } from 'react'

export const ThreeDotsSvg: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="12.5" cy="12" r="2.5" fill="#2E2A29" />
      <circle cx="20" cy="12" r="2.5" fill="#2E2A29" />
      <circle cx="5" cy="12" r="2.5" fill="#2E2A29" />
    </svg>
  )
}
