const formAction = '/form-action'
export const changeLanguagePath = `${formAction}/change-language`
export const setCookieBannerPopUpRulesPath = `${formAction}/set-cookie-banner-pop-up-rules-bottom`
export const setCookieInstallMobileAppBannerPath = `${formAction}/set-cookie-install-banner-app`

export const sendStatisticsInstallMobileAppBannerPath =
  '/send-statistics-instal-mobile-app-banner'

export const languageFormName = 'language'
export const pathnameFormName = 'pathname'
