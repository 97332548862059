import { MouseEvent } from 'react'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { VendorSocialList } from 'common-constants/vendorSocialList'
import { fetchUniNoticeAction } from 'actions/uninotice/uniNoticeAction'
import { externalMessengerOpenForAuthId } from 'components/uninotice/uniNoticeIdList'

export const NOTIFICATION_REQUIRED_VENDORS = [
  VendorSocialList.telegram,
  VendorSocialList.whatsapp,
  VendorSocialList.viber,
]

/**
 * Этот хук возвращает обработчик клика, который нужен для того,
 * чтобы определить и уведомить пользователя о том,
 * что данный способ авторизации работает только в том случае,
 * если у пользователя установлен выбранный мессенджер.
 */
export function useVendorAppInstalledNotification() {
  const dispatch = useAppDispatch()

  return {
    handleVendorClick(
      event: MouseEvent<HTMLElement>,
      vendorName: VendorSocialList
    ) {
      if (NOTIFICATION_REQUIRED_VENDORS.includes(vendorName)) {
        event.preventDefault()

        return dispatch(
          fetchUniNoticeAction(externalMessengerOpenForAuthId, {
            messenger: vendorName,
            flow: 'registration',
          })
        )
      }
    },
  }
}
