import { StepRegistrationFieldList } from 'reducers/registration/stepRegistrationReducer'

export const truncateDateValueToMaxLength = (name: string, value: string) => {
  switch (name) {
    case StepRegistrationFieldList.day:
    case StepRegistrationFieldList.month: {
      const maxLength = 2
      if (value.length > maxLength) {
        return value.slice(0, maxLength)
      }
      break
    }
    case StepRegistrationFieldList.year: {
      const maxLength = 4
      if (value.length > maxLength) {
        return value.slice(0, maxLength)
      }
    }
  }

  return value
}
