import { LoginWithEmail } from 'components/block/LoginVariants/LoginWithEmail'
import { LoginWithVendor } from 'components/block/LoginVariants/LoginWithVendor'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC } from 'react'

export const LastAuthMethodButton: FC<{
  onVendorClick: (vendorName: string) => void
}> = ({ onVendorClick }) => {
  const { lastAuthMethod, vendors } = useShallowEqualSelector(
    ({ authorizationReducer: { lastAuthMethod, vendors } }) => ({
      lastAuthMethod,
      vendors,
    })
  )

  const vendor = vendors.find(({ name }) => name === lastAuthMethod)

  if (!vendor) {
    return <LoginWithEmail variant="in-button" />
  }

  return (
    <LoginWithVendor
      vendor={vendor}
      variant="in-button"
      onVendorClick={onVendorClick}
    />
  )
}
