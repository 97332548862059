import { styled } from '@linaria/react'
import { buttonsBlockCss } from 'components/designSystem/Button/ButtonsBlock'
import { PrimaryButton } from 'components/designSystem/Button/PrimaryButton'
import { breakpoints } from 'components/designSystem/shared/breakpoints'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { loginVariantsPath } from '../Boarding/boarding.paths'
import { selectGenderPath } from './paths'

export const CreateProfileOrLoginBlock: FC = () => {
  const {
    locale,
  } = useShallowEqualSelector(({ systemReducer: { locale } }) => ({ locale }))

  return (
    <StyledButtonsBlock>
      <PrimaryButton
        to={mergeAllUrls(locale, selectGenderPath)}
        size="L"
        data-name="create-profile-action"
      >
        <FormattedMessage
          id="app.create.profile"
          defaultMessage="Создать профиль"
        />
      </PrimaryButton>

      <PrimaryButton
        to={mergeAllUrls(locale, loginVariantsPath)}
        color="white"
        size="L"
        border={false}
        withPadding
        data-name="login-action"
      >
        <FormattedMessage id="app.enter" defaultMessage="Войти" />
      </PrimaryButton>
    </StyledButtonsBlock>
  )
}

const StyledButtonsBlock = styled.div`
  ${buttonsBlockCss};
  flex-grow: 1;
  padding-top: var(--spacing-12px, 12px);
  @media (min-width: ${breakpoints.mobile}px) {
    padding-top: var(--spacing-24px, 24px);
  }
`
