import React, { FC, SVGProps } from 'react'

export const PlusSvg: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4064_6504)">
        <path
          d="M1.75098 8.75007C1.06062 8.75007 0.500977 9.30971 0.500977 10.0001C0.500977 10.6904 1.06062 11.2501 1.75098 11.2501H8.75056L8.75056 18.2496C8.75056 18.94 9.3102 19.4996 10.0006 19.4996C10.6909 19.4996 11.2506 18.94 11.2506 18.2496V11.2501H18.2501C18.9405 11.2501 19.5001 10.6904 19.5001 10.0001C19.5001 9.30971 18.9405 8.75007 18.2501 8.75007H11.2506L11.2506 1.75049C11.2506 1.06013 10.6909 0.500489 10.0006 0.500488C9.3102 0.500489 8.75056 1.06013 8.75056 1.75049V8.75007L1.75098 8.75007Z"
          fill="#F2F5F7"
        />
      </g>
      <defs>
        <clipPath id="clip0_4064_6504">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
