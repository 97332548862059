import React, { SVGProps } from 'react'

export const LipsSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.06568 19.1847C6.98598 19.7367 11.499 21.0341 16.1219 18.4343L16.2934 18.335C20.8521 15.6173 21.9878 11.0453 22.4704 9.10228C22.52 8.90248 22.5627 8.73049 22.6016 8.59116C23.018 7.09696 23.3464 6.49937 23.3464 6.49937C23.3464 6.49937 23.6226 5.95937 23.4342 5.63199C23.2458 5.30462 22.8634 5.29842 22.8634 5.29842C22.1562 5.25267 20.8234 4.81088 20.8234 4.81088C18.7357 4.00613 17.9573 3.84469 16.9766 3.64129C16.4326 3.52846 15.8264 3.40273 14.8999 3.14714C12.3033 2.43077 11.3735 4.56293 11.3735 4.56293L10.1738 7.507C10.0698 7.73842 9.83339 7.70417 9.83339 7.70417L6.69179 7.27432C6.69179 7.27432 4.38738 7.01524 3.70627 9.62988C3.46373 10.561 3.26931 11.1501 3.095 11.6783C2.77982 12.6333 2.53037 13.3892 2.18093 15.6075C2.18093 15.6075 1.89595 16.9862 1.58184 17.6235C1.58184 17.6235 1.39599 17.9588 1.58442 18.2862C1.77284 18.6135 2.37716 18.6436 2.37716 18.6436C2.37716 18.6436 3.05729 18.6571 4.55552 19.0424C4.69597 19.0784 4.86694 19.1276 5.06568 19.1847ZM9.51793 13.1461C9.02578 14.2074 8.16286 14.8201 8.16286 14.8201L8.16239 14.8219C8.95394 14.4486 9.96356 14.4567 9.96356 14.4567C9.96356 14.4567 12.2464 14.42 13.3643 13.7725C14.4823 13.1251 15.3764 11.9719 15.8012 11.246C16.2265 10.5184 17.2355 9.56724 17.2355 9.56724C16.4077 10.0173 14.9903 9.94894 14.9903 9.94894C13.8705 9.91958 12.8303 10.1126 11.8089 10.7041C10.7875 11.2957 10.0101 12.0849 9.51793 13.1461Z"
        fill="currentColor"
      />
    </svg>
  )
}
