import {
  isLoveMailru,
  isMamba,
  mailruPartnerIds,
  mambaPartnerIds,
} from 'common/constants'
import { mambaPartnerId } from 'components/system/third-party/vk/vk.constants'

/**
 * 4745247 (mamba) – используем на mamba.ru
 * 5040638 (love.mail) – используем на love.mail.ru
 * 2146727 (default) используем для всех остальных партнеров
 *
 * https://redmine.mamba.ru/issues/108173#note-15
 * @param partnerId
 */
export const findVkAppId = (partnerId: number) => {
  if (isLoveMailru(partnerId)) {
    return 5040638
  }

  if (isMamba(partnerId)) {
    return mambaPartnerId
  }

  return 2146727
}
