import React, { SVGProps } from 'react'

export const ChatSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={64}
      height={64}
      viewBox="0 0 64 64"
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M32 53.333c13.377 0 24-10.385 24-23.768C56 16.182 45.377 5.333 32 5.333s-24 10.617-24 24c0 4.756.925 9.423 3.291 13.166-1.294 2.964-3.139 6.495-5.459 9.621-.38.512-.478 1.117-.499 1.677C5.354 54.899 6.235 56 7.556 56c.062-.005.597-.062.66-.073 4.075-.683 8.524-2.078 12.87-4.612 3.225 1.589 7.076 2.018 10.914 2.018Z"
      />
    </svg>
  )
}
