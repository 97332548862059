import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { RestorePasswordSuccess } from './RestorePasswordSuccess'

export const RestorePasswordEmailSuccessStep: FC = () => {
  const { email } = useShallowEqualSelector(
    ({
      authorizationReducer: {
        form: { email },
      },
    }) => ({
      email,
    })
  )

  return (
    <RestorePasswordSuccess
      title={
        <FormattedMessage
          id="settings.confirmation"
          defaultMessage="Подтверждение"
        />
      }
      description={
        <FormattedMessage
          id="app.restore_link_sent_to_email"
          defaultMessage="Мы отправили ссылку для подтверждение смены пароля на: {email}."
          values={{ email }}
        />
      }
    />
  )
}
