import { styled } from '@linaria/react'
import {
  resetPasswordEmailNextAction,
  resetPasswordPhoneNextAction,
} from 'actions/authorization/resetPasswordAction'
import { updateAuthFieldAction } from 'actions/authorizationAction'
import { Input } from 'components/designSystem/Input/Input'
import { MainLayout } from 'components/designSystem/layout/MainLayout/MainLayout'
import { isValidEmail } from 'functions/isValidEmail'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { push } from 'functions/router'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { useLayoutContext } from 'hooks/useLayoutContext'
import { useReducersInsert } from 'hooks/useReducersInsert'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { isValidPhoneNumber } from 'libphonenumber-js'
import React, {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  MouseEventHandler,
  useRef,
  useState,
} from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { AuthorizationForm } from 'reducers/authorization/AuthorizationForm'
import { counterDownReducer } from 'reducers/utility/counterDown/counterDownReducer'
import { NextStepButton } from './NextStepButton'
import {
  restorePasswordEmailSuccessPath,
  restorePasswordEnterPhoneCodePath,
} from './paths'
import { StepCommonProps } from './types'

export const RestorePasswordStep: FC<StepCommonProps> = ({ active }) => {
  useReducersInsert({
    counterDown: counterDownReducer,
  })

  const {
    resettingPassword,
    unexpectedServerError,
    phone,
    locale,
  } = useShallowEqualSelector(
    ({
      authorizationReducer: {
        resettingPassword,
        unexpectedServerError,
        form: { phone },
      },
      systemReducer: { locale },
    }) => ({
      resettingPassword,
      unexpectedServerError,
      phone,
      locale,
    })
  )

  const dispatch = useAppDispatch()
  const intl = useIntl()
  const [value, setValue] = useState('')
  const inputEmailOrPhoneRef = useRef<HTMLInputElement>(null)
  const isEmail = isValidEmail(value)
  const isPhone = isValidPhoneNumber(value)
  const canSubmit = isEmail || isPhone
  const loading = resettingPassword

  const submit = async () => {
    if (!canSubmit || loading) {
      return
    }

    if (isEmail) {
      dispatch(updateAuthFieldAction('email', value))
      const result = await dispatch(resetPasswordEmailNextAction(value))
      if (result['result']['ok']) {
        dispatch(push(mergeAllUrls(locale, restorePasswordEmailSuccessPath)))
      }
    }

    if (isPhone) {
      const formValue: AuthorizationForm['phone'] = {
        ...phone,
        phoneNumberFull: value,
      }

      dispatch(updateAuthFieldAction('phone', formValue))
      const result = await dispatch(resetPasswordPhoneNextAction(false))
      if (result?.['result']['ok']) {
        dispatch(push(mergeAllUrls(locale, restorePasswordEnterPhoneCodePath)))
      }
    }
  }

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      submit()
    }
  }
  const handleChange: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
    setValue(target.value.trim())
  }
  const handleNextButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
    submit()
  }

  const error = Boolean(unexpectedServerError)

  return (
    <MainLayout
      noBorder
      backButtonVisible={false}
      title={
        <FormattedMessage
          id="app.where.to.send_password"
          defaultMessage="Куда отправить{br}новый пароль?"
          values={{ br: <br /> }}
        />
      }
      titleFontSize={32}
      description={
        <FormattedMessage
          id="app.enter.email.or.password"
          defaultMessage="Укажи email или номер телефона, на которые регистрировался."
        />
      }
      children={
        <InputEmailOrPhone
          ref={inputEmailOrPhoneRef}
          name="email-or-phone"
          type="text"
          value={value}
          placeholder={intl.formatMessage(messages.emailOrPhone)}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          required
          disabled={!active || loading}
          invalid={error}
        />
      }
      footerElement={
        <NextStepButton
          visible={active}
          active={canSubmit}
          onClick={handleNextButtonClick}
          loading={loading}
        />
      }
    />
  )
}

const messages = defineMessages({
  emailOrPhone: {
    id: 'app.email.or.phone',
    defaultMessage: 'Email или телефон',
  },
})

const InputEmailOrPhone = styled(Input)``
