import React, { SVGProps } from 'react'

export const WomanSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2395 22.9104C10.2395 23.4835 9.75838 23.943 9.19175 23.857C5.45518 23.2904 0.37501 20.5958 0.0192848 12.6953C-0.513609 0.860058 10.1437 -0.832409 13.874 0.294759C14.1972 0.392427 14.5404 0.485873 14.8978 0.583163L14.8978 0.583171C18.6646 1.60869 24 3.06126 24 14.3857C24 17.1034 22.6226 22.0043 16.5498 23.5322C15.9747 23.6769 15.4285 23.2461 15.4096 22.6534C15.3276 20.0737 15.4752 16.3346 16.527 16C18.0988 15.5 18.6228 12.5 17.0509 13C15.7934 13.4 15.4791 12.5 15.4791 12C15.4791 8.4 13.0339 7.16667 11.8114 7C7.56958 7 5.96937 8.9391 5.36569 11.0059C5.40994 11.002 5.45474 11 5.5 11C6.32843 11 7 11.6716 7 12.5C7 13.3284 6.32843 14 5.5 14C5.32567 14 5.15829 13.9703 5.00265 13.9156C5 14.1218 5 14.3175 5 14.5C5 17 6.04791 19.5 8.14372 19.5C9.82037 19.5 10.2395 20.8333 10.2395 21.5V22.9104ZM12 12.5C12 13.3284 11.3284 14 10.5 14C9.67157 14 9 13.3284 9 12.5C9 11.6716 9.67157 11 10.5 11C11.3284 11 12 11.6716 12 12.5Z"
      fill="#2E2A29"
    />
  </svg>
)
