import React, { SVGProps } from 'react'

export const PointWithEyeSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5 12C13.6046 12 14.5 11.1046 14.5 10C14.5 8.89543 13.6046 8 12.5 8C11.3954 8 10.5 8.89543 10.5 10C10.5 11.1046 11.3954 12 12.5 12Z"
        fill="#2E2A29"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 10.2266C4.5 15.3499 7.78229 19.2075 11.2099 22.5637C11.7978 23.1394 12.7677 23.151 13.4013 22.626C15.7747 20.6594 20.5 16.6097 20.5 10.2266C20.5 5.68323 16.9183 2 12.5 2C8.08172 2 4.5 5.68323 4.5 10.2266ZM6.5 9.84212C8.3 7.69739 13.22 4.69477 18.5 9.84212C16.5 12.2251 11.3 15.5613 6.5 9.84212Z"
        fill="#2E2A29"
      />
    </svg>
  )
}
