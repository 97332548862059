export const SUPPORT_OPEN_TICKET_TOGGLE = 'SUPPORT_OPEN_TICKET_TOGGLE'

interface ChatSupportOpenTicketAction {
  type: typeof SUPPORT_OPEN_TICKET_TOGGLE
}

export const chatSupportOpenTicketAction = () => ({
  type: SUPPORT_OPEN_TICKET_TOGGLE,
})

export type ChatMessengerSupportTypes = ChatSupportOpenTicketAction
