import loadable from '@loadable/component'
import { useIsIosVersionWithSvgAnimationIssues } from 'hooks/useIsIosVersionWithSvgAnimationIssues'
import React, { FC } from 'react'
import { VerticalWaveSvgNative } from './VerticalWaveSvgNative'

export const VerticalWave: FC<{
  step: number
  backgrounds: string[]
}> = (props) => {
  const iosVersionWithSvgAnimationIssues = useIsIosVersionWithSvgAnimationIssues()

  if (iosVersionWithSvgAnimationIssues) {
    return <VerticalWaveLottieLoadable {...props} />
  }

  return <VerticalWaveSvgNative {...props} />
}

const VerticalWaveLottieLoadable = loadable(() =>
  import('./VerticalWaveLottie')
)
